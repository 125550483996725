/* src/styling/Site.css */

/* General styles for the entire app */
.App {
  text-align: center; /* Center text alignment for the app */
}

/* Header styles */
.App-header {
  background-color: #282c34; /* Dark background color for the header */
  min-height: 100vh; /* Minimum height to cover the full viewport */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  font-size: calc(10px + 2vmin); /* Responsive font size */
  color: white; /* White text color */
}

/* Heading styles */
h1, h2, h3, h4, h5 {
  margin: 20px 0; /* Margin for headings */
}

/* Paragraph styles */
p {
  margin: 10px 0; /* Margin for paragraphs */
}

/* Form styles */
form {
  display: flex; /* Use flexbox for form layout */
  flex-direction: column; /* Stack form elements vertically */
  align-items: flex-start; /* Align items to the start */
  margin: 20px 0; /* Margin for the form */
}

/* Styles for form divs */
form div {
  margin-bottom: 10px; /* Space between form fields */
}

/* Label styles */
form label {
  margin-bottom: 5px; /* Space below labels */
}

/* Input and textarea styles */
form input, form textarea {
  padding: 8px; /* Padding inside input fields */
  width: 300px; /* Fixed width for input fields */
}

/* Button styles */
button {
  padding: 10px 15px; /* Padding for buttons */
  background-color: #333; /* Dark background for buttons */
  color: white; /* White text color for buttons */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
}

/* Button hover effect */
button:hover {
  background-color: #555; /* Lighter background on hover */
}

/* Newspaper Theme Styles */
body {
  background-color: #f4f1ea; /* Cream colored background */
  font-family: 'Courier New', Courier, monospace;
  color: #2b2b2b; /* Charcoal color for text */
  line-height: 1.6;
}

.Newspaper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee; /* Slightly lighter cream for content area */
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
  color: #2b2b2b;
}

.Dateline {
  font-style: italic;
  margin-top: 10px;
}

.MainStory {
  border-bottom: 1px solid #888;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.Headline {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Times New Roman", serif;
}

.NewsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.NewsItem {
  border: 1px solid #d3d0c9; /* Lighter border color */
  padding: 20px;
  background-color: #f8f5ee; /* Slightly lighter cream */
}

.NewsItem h3 {
  font-family: "Times New Roman", serif;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.ReadMore {
  color: #2b2b2b;
  text-decoration: none;
  font-style: italic;
}

.ReadMore:hover {
  text-decoration: underline;
}

.PriceTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.PriceTable th, .PriceTable td {
  border: 1px solid #2b2b2b;
  padding: 10px;
  text-align: center;
}

.ProcessSteps {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.Step {
  text-align: center;
  flex: 1;
}

.StepNumber {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2b2b2b;
  color: #f4f1ea;
  line-height: 30px;
  margin-bottom: 10px;
}

.CTA {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2b2b2b;
  color: #f4f1ea;
  text-decoration: none;
  margin-top: 20px;
}

.CTA:hover {
  background-color: #3d3d3d;
}
