.print-locations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.location-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.location-column h3 {
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
  text-align: center;
  color: #333;
}

.location-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.location-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.location-option:hover {
  background-color: #f5f5f5;
}

.location-option input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #7C8C6F;
}

.checkbox-label {
  font-size: 0.95em;
  color: #444;
}

@media (max-width: 768px) {
  .print-locations {
    grid-template-columns: 1fr;
    gap: 20px;
  }
} 