.shirt-color-selector {
  margin: 0;
  padding: 0;
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-grid.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
  padding: 6px;
  overflow-y: visible;
  width: 100%;
  background: #f8f8f8;
  border-radius: 4px;
  min-height: 70px;
}

.color-swatch {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  flex: 0 0 auto;
  background-size: cover;
  background-position: center;
}

.color-swatch:hover {
  border-color: #2b2b2b;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(43, 43, 43, 0.2);
}

.color-swatch.selected {
  border-color: #2b2b2b;
  box-shadow: 0 0 0 1px #2b2b2b;
}

.color-name {
  display: none;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #2b2b2b;
  color: white;
  padding: 3px 6px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 1;
  font-size: 10px;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.color-swatch:hover .color-name {
  display: block;
}

.selected-color-info {
  margin-top: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  color: #2b2b2b;
  padding: 4px 8px;
  border-radius: 4px;
  background: #f8f8f8;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .color-grid.horizontal {
    padding: 6px;
    gap: 3px;
  }
  
  .color-swatch {
    width: 20px;
    height: 20px;
  }
  
  .color-name {
    font-size: 9px;
    padding: 2px 4px;
  }
} 