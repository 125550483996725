.order-details {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.order-details h2 {
  font-size: 24px;
  margin-bottom: 24px;
  color: #333;
}

.details-section {
  margin-bottom: 32px;
}

.details-section h3 {
  font-size: 18px;
  margin-bottom: 16px;
  color: #444;
}

.size-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
}

.size-input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  background: #f8f8f8;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.size-input-group:hover {
  background: #f0f0f0;
}

.size-input-group label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.quantity-controls input {
  width: 60px;
  height: 36px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.quantity-btn {
  width: 36px;
  height: 36px;
  border: none;
  background: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.quantity-btn:hover {
  background: #d0d0d0;
}

.total-quantity {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  color: #666;
}

.delivery-selection select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background: #fff;
  cursor: pointer;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 16px;
}

.back-button,
.next-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button {
  background: #f0f0f0;
  color: #333;
}

.back-button:hover {
  background: #e0e0e0;
}

.next-button {
  background: #2b2b2b;
  color: white;
}

.next-button:hover {
  background: #404040;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .size-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .navigation-buttons {
    flex-direction: column;
  }
  
  .back-button,
  .next-button {
    width: 100%;
  }
} 