.DesignPage {
  color: #2b2b2b;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding: 40px 20px 20px;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
}

.Dateline {
  font-style: italic;
  margin: 10px 0 0;
}

.MainStory {
  margin: 40px auto;
}

.LeadParagraph {
  font-size: 1.3em;
  line-height: 1.6;
  margin: 20px 0;
  font-family: "Times New Roman", serif;
}

.ImagePlaceholder {
  background-color: #e0dcd3;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border: 1px dashed #2b2b2b;
}

.PlaceholderText {
  font-style: italic;
  color: #666;
}

.ArticleGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 40px auto;
}

.Feature {
  border: 1px solid #d3d0c9;
  padding: 30px;
  background-color: #fff;
}

.Feature h3 {
  font-family: "Times New Roman", serif;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
}

.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

.AwardsList {
  margin-top: 20px;
}

.Award {
  margin-bottom: 20px;
}

.Award h4 {
  font-family: "Times New Roman", serif;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.ProcessSteps {
  margin-top: 20px;
}

.Step {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d0c9;
}

.Step:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.Step h4 {
  font-family: "Times New Roman", serif;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.ContactSection {
  text-align: center;
  margin: 60px auto;
  padding: 40px;
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.ContactSection h3 {
  font-family: "Times New Roman", serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.ContactSection p {
  margin-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.CTA {
  display: inline-block;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.CTA.Primary {
  background-color: #2b2b2b;
  color: #f8f5ee;
}

.CTA:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .ArticleGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .Feature.span-2 {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .Masthead h1 {
    font-size: 2.5em;
  }
  
  .ArticleGrid {
    grid-template-columns: 1fr;
  }
  
  .Feature.span-1,
  .Feature.span-2 {
    grid-column: span 1;
  }
} 