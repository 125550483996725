/* Screen Printing page specific styles */
.ScreenPrintingPage {
  color: #2b2b2b;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.ScreenPrintingPage h1,
.ScreenPrintingPage h2,
.ScreenPrintingPage h3,
.ScreenPrintingPage p,
.ScreenPrintingPage div {
  color: #2b2b2b;
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
}

.Dateline {
  font-style: italic;
  margin-top: 10px;
}

.MainStory.Feature {
  border: 1px solid #d3d0c9;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f5ee;
}

.MainStory.Feature h3 {
  font-family: "Times New Roman", serif;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
}

.VideoWrapper {
  margin: 20px 0;
  border: 1px solid #2b2b2b;
}

.ProcessVideo {
  width: 100%;
  display: block;
}

.ArticleGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.Feature {
  border: 1px solid #d3d0c9;
  padding: 20px;
  background-color: #f8f5ee;
}

.Feature h3 {
  font-family: "Times New Roman", serif;
  font-size: 1.5em;
  margin-bottom: 15px;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
}

/* Span classes for different widths */
.Feature.span-1 {
  grid-column: span 1;
}

.Feature.span-2 {
  grid-column: span 2;
}

.Feature.span-3 {
  grid-column: span 3;
}

.FeaturedStory {
  border: 1px solid #d3d0c9;
  padding: 20px;
  background-color: #f8f5ee;
  margin: 40px auto;
}

.SectionHeader {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}

.NewsPreview h4 {
  font-family: "Times New Roman", serif;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.PricingSection {
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
  margin: 40px auto;
  padding: 20px;
}

.PriceTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.PriceTable th {
  background: #2b2b2b;
  color: #f8f5ee;
  padding: 12px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
}

.PriceTable td {
  padding: 12px;
  border-bottom: 1px solid #d3d0c9;
}

.ReadMore {
  color: #2b2b2b;
  text-decoration: none;
  font-style: italic;
}

.ReadMore:hover {
  text-decoration: underline;
}

/* Timeline styling */
.Timeline {
  border: 1px solid #d3d0c9;
  padding: 20px;
}

.TimelineItem {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d3d0c9;
}

.TimelineItem:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.TimelineNote {
  display: block;
  font-size: 0.9em;
  font-style: italic;
  color: #555;
}

/* Brand Grid styling */
.BrandGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.BrandCategory h4 {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
}

.BrandCategory ul {
  list-style: none;
  padding: 0;
}

.BrandCategory li {
  padding: 5px 0;
  border-bottom: 1px solid #d3d0c9;
}

.BrandCategory li:last-child {
  border-bottom: none;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .ArticleGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .Feature.span-2,
  .Feature.span-3 {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .ScreenPrintingPage {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }
  
  .ArticleGrid {
    grid-template-columns: 1fr;
  }
  
  .Feature.span-1,
  .Feature.span-2,
  .Feature.span-3 {
    grid-column: span 1;
  }
  
  .Masthead h1 {
    font-size: 2.5em;
  }
  
  .MainStory.Feature h3 {
    font-size: 1.8em;
  }
  
  .PriceGrid {
    overflow-x: auto;
  }
  
  .PriceTable {
    min-width: 300px;
  }
  
  .NewsPreview h4 {
    font-size: 1.5em;
    word-wrap: break-word;
  }
  
  .BrandGrid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .ScreenPrintingPage {
    padding: 8px;
  }
  
  .Masthead h1 {
    font-size: 2em;
  }
  
  .MainStory.Feature h3 {
    font-size: 1.5em;
  }
  
  .Feature h3 {
    font-size: 1.3em;
  }
  
  .SectionHeader {
    font-size: 1.3em;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
}

/* Fix for the ProcessSteps section */
@media (max-width: 768px) {
  .ProcessSteps {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .Step {
    padding: 10px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  
  .Step h4 {
    margin: 0 0 0 10px;
  }
}

/* Fix for the Timeline section */
@media (max-width: 768px) {
  .Timeline {
    padding: 10px;
  }
}

/* Fix for the DesignOptions section */
@media (max-width: 768px) {
  .DesignOptions {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .Option {
    padding: 10px;
  }
}

/* Fix for the BrandGrid section */
@media (max-width: 768px) {
  .BrandGrid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

/* Add to your existing CSS */
.DesignOptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}

.Option {
  padding: 15px;
}

.Option h4 {
  font-family: "Times New Roman", serif;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #2b2b2b;
}

@media (max-width: 768px) {
  .DesignOptions {
    grid-template-columns: 1fr;
  }
}

.ProcessSteps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.Step {
  text-align: center;
  padding: 20px;
}

.Step h4 {
  font-family: "Times New Roman", serif;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #2b2b2b;
}

.Step p {
  font-size: 0.9em;
  line-height: 1.4;
}

@media (max-width: 968px) {
  .ProcessSteps {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .ProcessSteps {
    grid-template-columns: 1fr;
  }
} 