/* Home page specific styles */
.HomePage {
  color: #2b2b2b;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.HomePage h1,
.HomePage h2,
.HomePage h3,
.HomePage p,
.HomePage div {
  color: #2b2b2b;
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
  color: #2b2b2b;
}

.Dateline {
  font-style: italic;
  margin-top: 10px;
  color: #2b2b2b;
}

.MainStory {
  border-bottom: 1px solid #888;
  margin-bottom: 30px;
  padding-bottom: 20px;
  color: #2b2b2b;
}

.Headline {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Times New Roman", serif;
  color: #2b2b2b;
}

.Subheading {
  font-size: 1.2em;
  color: #2b2b2b;
}

.LeadParagraph {
  font-size: 1.1em;
  color: #2b2b2b;
}

.NewsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.NewsItem {
  border: 1px solid #d3d0c9;
  padding: 20px;
  background-color: #f8f5ee;
  color: #2b2b2b;
}

.NewsItem h3 {
  font-family: "Times New Roman", serif;
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2b2b2b;
}

.ReadMore {
  color: #2b2b2b;
  text-decoration: none;
  font-style: italic;
}

.ReadMore:hover {
  text-decoration: underline;
}

/* Override any conflicting styles */
.App-header .HomePage * {
  color: #2b2b2b !important;
}

.PhotoSection {
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.PhotoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 30px;
  margin: 20px 0;
}

.NewsPhoto {
  position: relative;
  margin: 0;
  padding: 15px;
  background-color: #f8f5ee;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}

.NewsImage {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid #2b2b2b;
}

.NewsImage.placeholder {
  background-color: #f0f0f0;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.PhotoCaption {
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
  line-height: 1.4;
  color: #2b2b2b;
  text-align: justify;
}

.CaptionDate {
  font-weight: bold;
}

.SectionHeader {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px;
  color: #2b2b2b;
}

.ProcessSection {
  margin: 40px 0;
  padding: 20px 0;
  border-top: 2px solid #2b2b2b;
}

.ProcessSteps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}

.Step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  width: 18%;
}

.StepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2b2b2b;
  color: #f8f5ee;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .PhotoGrid {
    grid-template-columns: 1fr;
  }
  
  .NewsPhoto {
    margin: 10px 0;
  }
  
  .ProcessSteps {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .Step {
    flex-direction: row;
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    align-items: center;
  }
  
  .StepNumber {
    margin-right: 15px;
    margin-bottom: 0;
  }
  
  .Step h4 {
    margin: 0;
  }
} 