.garment-inventory-dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.refresh-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.refresh-button:disabled {
  background-color: #ccc;
}

.last-updated {
  color: #666;
  font-size: 0.9em;
}

.inventory-grid {
  display: grid;
  gap: 30px;
}

.material-section {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

.material-info {
  color: #666;
  margin-bottom: 15px;
}

.color-row {
  display: grid;
  grid-template-columns: 40px 1fr 2fr;
  gap: 15px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.color-info {
  display: flex;
  flex-direction: column;
}

.color-name {
  font-weight: bold;
}

.inventory-total {
  color: #666;
  font-size: 0.9em;
}

.size-breakdown {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.size-detail {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  font-size: 0.9em;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.connection-status {
  margin-top: 10px;
  font-size: 0.9em;
}

.status {
  font-weight: bold;
}

.status.checking {
  color: #f0ad4e;
}

.status.connected {
  color: #5cb85c;
}

.status.error {
  color: #d9534f;
}

.test-controls {
  margin: 20px 0;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
}

.test-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.test-button:disabled {
  background-color: #ccc;
}

.sync-status {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.sync-status.success {
  background-color: #d4edda;
  color: #155724;
}

.sync-status.error {
  background-color: #f8d7da;
  color: #721c24;
}

.data-display {
  margin-top: 20px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  overflow-x: auto;
}

.data-display pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.warehouse-inventory {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.warehouse-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.warehouse-name {
  font-weight: bold;
}

.warehouse-qty {
  color: #666;
}

.total-inventory {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #ddd;
  font-size: 1.1em;
}

.color-swatches {
  margin-top: 20px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 4px;
}

.swatch-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.swatch-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.swatch-preview {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-size: cover;
  background-position: center;
}

.swatch-name {
  font-size: 0.8em;
  margin-top: 5px;
  text-align: center;
}

.status-message {
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
}

.status-message.success {
  background-color: #e6ffe6;
  color: #006600;
}

.status-message.error {
  background-color: #ffe6e6;
  color: #660000;
} 