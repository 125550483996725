.due-date-selection {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.date-picker-section {
  margin-bottom: 20px;
}

.date-picker-section label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.date-picker-section input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.date-picker-section input:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 2px rgba(124, 140, 111, 0.25);
}

.date-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
  margin-top: 15px;
}

.standard-date {
  margin-bottom: 10px;
  font-weight: bold;
}

.info-tooltip {
  margin-left: 8px;
  font-weight: normal;
  font-style: italic;
  color: #666;
}

.rush-fee-warning {
  margin-top: 15px;
  padding: 10px;
  background-color: #fff3cd;
  border-left: 4px solid #ffc107;
  color: #856404;
}

.rush-fee-warning p {
  margin: 0;
  color: #5F6B54;
}

.rush-note {
  font-size: 0.9em;
  margin-top: 5px;
}

.delivery-note {
  margin-top: 15px;
  font-style: italic;
  color: #666;
}

.order-deadline {
  margin-top: 15px;
  padding: 10px;
  background-color: #f0f2ee;
  border-left: 4px solid #7C8C6F;
  color: #5F6B54;
}

.cutoff-note {
  font-size: 0.9em;
  margin-top: 5px;
  font-style: italic;
}

.warning-message {
  padding: 12px 16px;
  margin: 12px 0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
}

/* Light yellow background for rush fee warning */
.warning-message.rush {
  background-color: rgba(255, 244, 199, 0.5);
  border: 1px solid rgba(255, 182, 0, 0.3);
  color: #664d03;
}

/* Light red background for super rush warning */
.warning-message.super-rush {
  background-color: rgba(255, 218, 218, 0.5);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #842029;
}

@media (max-width: 768px) {
  .due-date-selection {
    padding: 15px;
  }
} 