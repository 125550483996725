.quantity-grid {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.size-inputs {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.size-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.size-label {
  font-size: 1.1em;
  color: #333;
  font-weight: bold;
}

.quantity-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  text-align: center;
}

.quantity-input:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 2px rgba(124, 140, 111, 0.25);
}

.quantity-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  margin-top: 10px;
  border-top: 2px solid #eee;
  font-weight: bold;
  gap: 15px;
}

.total-label {
  color: #333;
}

.total-value {
  font-size: 1.2em;
  color: #7C8C6F;
  min-width: 60px;
  text-align: center;
}

/* Remove spinner buttons from number input */
.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input[type=number] {
  -moz-appearance: textfield;
}

/* Responsive design */
@media (max-width: 768px) {
  .size-inputs {
    flex-wrap: wrap;
    gap: 10px;
  }

  .size-column {
    flex-basis: calc(25% - 10px); /* Show 4 columns on medium screens */
  }
}

@media (max-width: 480px) {
  .size-column {
    flex-basis: calc(33.33% - 10px); /* Show 3 columns on small screens */
  }
  
  .quantity-grid {
    padding: 10px;
  }
} 