.order-confirmation {
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #f4f1ea;
  font-family: 'Courier New', Courier, monospace;
}

.confirmation-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirmation-header {
  text-align: center;
  margin-bottom: 40px;
}

.confirmation-header h1 {
  color: #2b2b2b;
  font-size: 2.5em;
  margin-bottom: 10px;
  font-family: "Times New Roman", serif;
}

.order-number {
  font-size: 1.2em;
  color: #8BA888;
  font-weight: 600;
}

.confirmation-content {
  margin-bottom: 40px;
}

.confirmation-message p {
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.next-steps {
  margin: 40px 0;
  display: grid;
  gap: 30px;
}

.step {
  padding: 20px;
  background-color: #f8f5ee;
  border-radius: 8px;
  border: 1px solid #e6e3dc;
}

.step h3 {
  color: #2b2b2b;
  margin-bottom: 10px;
  font-family: "Times New Roman", serif;
}

.step p {
  margin: 0;
  color: #555;
}

.confirmation-details {
  margin-top: 40px;
  padding: 20px;
  background-color: #f8f5ee;
  border-radius: 8px;
  border: 1px solid #e6e3dc;
}

.confirmation-details p {
  margin: 10px 0;
}

.confirmation-actions {
  text-align: center;
  margin-top: 40px;
}

.return-home {
  display: inline-block;
  padding: 15px 30px;
  background-color: #8BA888;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.return-home:hover {
  background-color: #7a9377;
}

@media (max-width: 768px) {
  .confirmation-container {
    padding: 20px;
  }
  
  .confirmation-header h1 {
    font-size: 2em;
  }
} 