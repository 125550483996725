.get-quote-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.quote-content h1 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.intro-text {
  text-align: center;
  margin-bottom: 30px;
  color: #666;
  font-size: 1.1em;
  line-height: 1.5;
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  width: 100%;
}

/* Remove spinner buttons from number input */
.form-group input[type="number"] {
  -moz-appearance: textfield;
}

.form-group input[type="number"]::-webkit-inner-spin-button,
.form-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 2px rgba(124, 140, 111, 0.2);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background-color: #7C8C6F;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  width: 80%;
}

.submit-button:hover {
  background-color: #6a7a5e;
}

.submit-button:disabled {
  background-color: #a5b59b;
  cursor: not-allowed;
}

.error-message {
  background-color: #fff3f3;
  color: #d32f2f;
  border-left: 4px solid #d32f2f;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 0 4px 4px 0;
  width: 80%;
}

@media (max-width: 768px) {
  .get-quote-container {
    padding: 20px;
    margin: 20px auto;
  }
  
  .form-group input,
  .form-group textarea,
  .submit-button {
    padding: 10px;
  }
  
  .form-group, .submit-button, .error-message {
    width: 90%;
  }
} 