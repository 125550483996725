.delivery-method {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.delivery-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.delivery-option {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delivery-option:hover {
  border-color: #7C8C6F;
  background-color: #f8f9fa;
}

.delivery-option input[type="radio"] {
  margin-top: 4px;
  accent-color: #7C8C6F;
}

.option-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.option-title {
  font-weight: bold;
  color: #333;
}

.option-description {
  font-size: 0.9em;
  color: #666;
}

.shipping-address {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.shipping-address h3 {
  margin-bottom: 20px;
  color: #333;
}

.address-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row.three-columns {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-size: 0.9em;
  color: #555;
}

.form-group input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.form-group input:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 2px rgba(124, 140, 111, 0.25);
}

@media (max-width: 768px) {
  .form-row.three-columns {
    grid-template-columns: 1fr;
  }
  
  .form-row {
    flex-direction: column;
  }
  
  .delivery-method {
    padding: 15px;
  }
} 