.artwork-upload {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Split into two equal columns */
  gap: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.info-column {
  padding: 20px;
}

.upload-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.artwork-type-selection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.artwork-type-selection label {
  font-weight: bold;
  color: #333;
  font-size: 1.1em;
}

.artwork-type-dropdown {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1em;
  color: #333;
  width: 100%;
  max-width: 350px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%237C8C6F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  transition: border-color 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.artwork-type-dropdown:hover {
  border-color: #7C8C6F;
}

.artwork-type-dropdown:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 3px rgba(124, 140, 111, 0.2);
}

.print-methods-info {
  font-size: 1.1em;
  color: #333;
  margin-bottom: 15px;
}

.methods-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.methods-list li {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  line-height: 1.4;
}

.methods-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #7C8C6F;
}

.upload-locations {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 450px;
}

.location-upload-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 12px;
  background-color: #fafafa;
}

.location-upload-container h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
  font-size: 0.95em;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.no-locations-message {
  color: #e74c3c;
  padding: 15px;
  background-color: #fdecea;
  border-radius: 4px;
  text-align: center;
  margin: 10px 0;
}

.upload-section {
  padding: 5px;
}

.upload-box {
  border: 2px dashed #7C8C6F;  /* Using our sage green */
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  cursor: pointer;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drag-active .upload-box {
  border-color: #5F6B54;  /* Darker sage for active state */
  background-color: #f0f2ee;  /* Light sage background */
}

.file-input {
  display: none;
}

.upload-label {
  cursor: pointer;
  display: block;
}

.upload-icon {
  width: 30px;
  height: 30px;
  margin: 0 auto 5px;
  color: #7C8C6F;
}

.upload-icon svg {
  width: 100%;
  height: 100%;
}

.upload-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.upload-text p {
  margin: 0;
  color: #333;
  font-size: 0.95em;
  font-weight: 500;
}

.upload-subtext {
  color: #666;
  font-size: 0.8em;
}

.file-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
}

.file-info .upload-icon {
  font-size: 1.2em;
  color: #5F6B54;
  margin-bottom: 0;
  flex-shrink: 0;
}

.file-name {
  font-weight: 500;
  color: #7C8C6F;
  word-break: break-word;
  font-size: 0.85em;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-size {
  color: #666;
  font-size: 0.75em;
}

.file-requirements {
  grid-column: span 2;
  margin-top: 20px;
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.supported-formats {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.file-specs {
  color: #666;
  font-size: 0.9em;
  line-height: 1.5;
}

.file-preview {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  flex-shrink: 0;
}

.file-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.file-details {
  flex: 1;
  min-width: 0; /* Ensures text truncation works */
}

.description-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
}

.description-container label {
  font-weight: bold;
  color: #333;
}

.design-description-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  resize: vertical;
  min-height: 120px; /* Reduced height for ~5 rows */
  color: #333;
  line-height: 1.5;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.design-description-input:focus {
  outline: none;
  border-color: #7C8C6F;
  box-shadow: 0 0 0 3px rgba(124, 140, 111, 0.2);
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

/* Add new progress bar styles */
.upload-progress {
  width: 100%;
  max-width: 200px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  margin: 8px auto;
}

.progress-bar {
  height: 100%;
  background-color: #7C8C6F;
  border-radius: 2px;
  animation: progressAnimation 1.5s ease-in-out infinite;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    opacity: 1;
  }
  50% {
    width: 100%;
    opacity: 0.5;
  }
  100% {
    width: 0%;
    opacity: 1;
  }
}

.upload-status-text {
  font-size: 0.9em;
  color: #666;
  margin: 0;
}

.delete-file-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.delete-file-button:hover {
  background-color: #f0f0f0;
  color: #e74c3c;
}

.delete-file-button svg {
  display: block;
}

/* Responsive design */
@media (max-width: 768px) {
  .artwork-upload {
    grid-template-columns: 1fr;  /* Stack on mobile */
    padding: 10px;
    gap: 15px;
  }
  
  .info-column,
  .upload-column {
    padding: 10px;
  }
  
  .upload-box {
    padding: 12px;
  }
  
  .file-requirements {
    grid-column: span 1;
  }
}