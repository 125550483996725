.HighVolumePricingPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.Masthead {
  text-align: center;
  border-bottom: 3px double #000;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.Masthead h1 {
  font-size: 3rem;
  margin: 0;
  letter-spacing: 1px;
}

.Dateline {
  font-style: italic;
  margin-top: 5px;
}

.MainStory {
  margin-bottom: 40px;
}

.Headline {
  font-size: 2.2rem;
  margin-bottom: 15px;
  text-align: center;
}

.LeadParagraph {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 25px;
}

.PricingFormula {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 25px;
  margin: 30px 0;
  text-align: center;
}

.Formula {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 1.5rem;
  margin: 20px 0;
}

.Formula span {
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #000;
}

.FormulaNotes {
  font-style: italic;
  margin-top: 20px;
}

.ArticleGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.Feature {
  border: 1px solid #ddd;
  padding: 25px;
}

.span-2 {
  grid-column: span 2;
}

.span-1 {
  grid-column: span 1;
}

.Feature h3 {
  text-align: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.Emphasis {
  font-size: 1.25rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 25px;
}

.ContactOptions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.ContactOption {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-width: 250px;
}

.ContactDetail {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 10px 0;
}

.ContactHours {
  font-style: italic;
  font-size: 0.9rem;
}

.BottomCTA {
  text-align: center;
  background-color: #f9f9f9;
  padding: 30px;
  border: 1px solid #ddd;
  margin-top: 40px;
}

.CTAButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.CTA {
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: bold;
  transition: background-color 0.3s;
}

.CTA:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .ArticleGrid {
    grid-template-columns: 1fr;
  }
  
  .span-2, .span-1 {
    grid-column: span 1;
  }
  
  .Formula {
    font-size: 1.2rem;
  }
  
  .ContactOptions {
    flex-direction: column;
  }
} 