.site-footer {
  background-color: #2b2b2b;
  color: #f8f5ee;
  padding: 40px 20px 20px;
  margin-top: 60px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.footer-section h3 {
  color: #f8f5ee;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 600;
}

.footer-section.contact address {
  font-style: normal;
  line-height: 1.6;
}

.footer-section.contact a,
.footer-section.links a {
  color: #f8f5ee;
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-section.contact a:hover,
.footer-section.links a:hover {
  color: #d3d0c9;
}

.footer-section.links nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-links {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.instagram-link {
  color: #f8f5ee;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.instagram-link:hover {
  color: #d3d0c9;
}

.footer-section.social {
  text-align: center;
}

/* Hours section styling */
.hours {
  margin-top: 15px;
}

.hours h4 {
  color: #f8f5ee;
  font-size: 1em;
  margin-bottom: 8px;
  font-weight: 500;
}

.hours p {
  margin: 0;
  color: #f8f5ee;
  line-height: 1.4;
}

.footer-bottom {
  max-width: 1200px;
  margin: 40px auto 0;
  padding-top: 20px;
  border-top: 1px solid rgba(248, 245, 238, 0.2);
  text-align: center;
  font-size: 0.9em;
  color: #d3d0c9;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-section.social {
    grid-column: span 2;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section.social {
    grid-column: span 1;
  }

  .footer-section.links nav {
    align-items: center;
  }
} 