.LocalNewsPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.Masthead {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 2px solid #2b2b2b;
}

.NewsArticle {
  margin-bottom: 60px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

.Dateline {
  font-style: italic;
  color: #666;
  margin-bottom: 20px;
}

.NewsArticle h2 {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.NewsArticle p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 15px;
}

.MainArticle {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
}

.RelatedArticles {
  margin: 60px auto;
  max-width: 1200px;
}

.RelatedArticles .SectionHeader {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Courier New', Courier, monospace;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
}

.ArticleGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

@media (max-width: 768px) {
  .ArticleGrid {
    grid-template-columns: 1fr;
  }
}

.NavigationArea {
  max-width: 800px;
  margin: 20px auto;
  padding: 0 30px;
}

.BackButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2b2b2b;
  color: #f8f5ee;
  border: none;
  cursor: pointer;
  font-family: "Times New Roman", serif;
  font-size: 1.1em;
  transition: opacity 0.2s;
  margin: 0;
}

.BackButton:hover {
  opacity: 0.8;
} 