.confirmation-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirmation-content h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.confirmation-message {
  background-color: #f0f2ee;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  border-left: 4px solid #7C8C6F;
}

.confirmation-message p {
  margin: 10px 0;
  font-size: 1.1em;
  line-height: 1.5;
  color: #333;
}

.what-happens-next {
  margin-bottom: 30px;
}

.what-happens-next h2 {
  color: #333;
  margin-bottom: 15px;
}

.what-happens-next ol {
  padding-left: 20px;
}

.what-happens-next li {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #444;
}

.contact-info {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.contact-info h2 {
  color: #333;
  margin-bottom: 15px;
}

.contact-info p {
  margin: 8px 0;
  color: #444;
}

.contact-info a {
  color: #7C8C6F;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-info a:hover {
  color: #5a664f;
  text-decoration: underline;
}

.navigation-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.navigation-links a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #7C8C6F;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navigation-links a:hover {
  background-color: #6a7a5e;
}

@media (max-width: 768px) {
  .confirmation-container {
    padding: 20px;
    margin: 20px auto;
  }
  
  .navigation-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .navigation-links a {
    width: 100%;
    text-align: center;
  }
} 