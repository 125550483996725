.AboutPage {
  color: #2b2b2b;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding: 40px 20px 20px;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
}

.Dateline {
  font-style: italic;
  margin: 10px 0 0;
}

.MainStory {
  margin: 40px auto;
}

.LeadParagraph {
  font-size: 1.3em;
  line-height: 1.6;
  margin: 20px 0 40px;
  font-family: "Times New Roman", serif;
}

.StoryContent {
  display: grid;
  gap: 40px;
  margin: 40px 0;
}

.Feature {
  border: 1px solid #d3d0c9;
  padding: 30px;
  background-color: #fff;
}

.Feature h3 {
  font-family: "Times New Roman", serif;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
}

.Feature p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.Feature p:last-child {
  margin-bottom: 0;
}

.ContactSection {
  text-align: center;
  margin: 60px auto;
  padding: 40px;
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.ContactSection h3 {
  font-family: "Times New Roman", serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.FutureNote {
  font-style: italic;
  font-size: 1.2em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .Masthead h1 {
    font-size: 2.5em;
  }
  
  .LeadParagraph {
    font-size: 1.2em;
  }
  
  .Feature {
    padding: 20px;
  }
} 