/* Ensure the Stripe Elements container takes full width */
.checkout-with-stripe-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Target Stripe's internal Elements containers to ensure they respect our layout */
.checkout-with-stripe-container .StripeElement,
.checkout-with-stripe-container iframe {
  width: 100% !important;
} 