.shirt-designer {
  display: flex;
  gap: 12px;
  padding: 12px;
}

.canvas-container {
  flex: 3;
  padding: 12px;
  background: white;
  border-right: 1px solid #e0e0e0;
}

.sidebar {
  width: 100%;
  max-width: 800px; /* Control maximum width */
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children */
}

.design-tools {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children */
}

.tool-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children */
}

/* Basic responsive design */
@media (max-width: 1024px) {
  .shirt-designer {
    flex-direction: column;
    height: auto;
  }

  .canvas-container,
  .sidebar {
    width: 100%;
  }
}

.design-canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.design-canvas-container canvas {
  max-width: 100%;
  height: auto;
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Make the Fabric.js canvas responsive */
.canvas-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.shirt-color-selector {
  padding: 15px;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 15px;
}

.color-swatch {
  aspect-ratio: 1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
  background-size: cover;
  background-position: center;
}

.color-swatch:hover {
  transform: scale(1.05);
}

.color-swatch.selected {
  box-shadow: 0 0 0 3px #0066cc;
}

.selected-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  font-size: 1.2em;
}

.selected-color-info {
  text-align: center;
  margin-top: 10px;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
  font-weight: 500;
}

.shirt-color-selector-loading,
.shirt-color-selector-error {
  padding: 20px;
  text-align: center;
  color: #666;
}

.shirt-color-selector-error {
  color: #dc3545;
} 