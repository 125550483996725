.screen-printing-order {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.order-section {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.order-section h2 {
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.size-grid {
  display: grid;
  gap: 10px;
  margin: 20px 0;
}

.total-quantity {
  font-weight: bold;
  margin-top: 10px;
  text-align: right;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  padding: 1rem;
}

/* Add responsive design */
@media (max-width: 768px) {
  .screen-printing-order {
    padding: 10px;
  }
  
  .order-section {
    padding: 15px;
  }
} 