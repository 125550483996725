.floating-price-display {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
  border: 1px solid #eee;
  min-width: 250px;
}

.price-content {
  padding: 15px 20px;
}

.quantity-summary {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.price-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.base-price {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.per-item {
  font-size: 1.1em;
  color: #7C8C6F;
  font-weight: bold;
}

.subtotal {
  color: #333;
}

.additional-fees {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  padding: 8px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.fee-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95em;
  color: #666;
}

.fee-amount {
  color: #333;
}

.total-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.total-price {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}

.price-note {
  font-size: 0.8em;
  color: #999;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .floating-price-display {
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
  }
} 