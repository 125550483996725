.custom-tshirts-landing {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 40px;
}

.hero-section h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
}

/* Quick Info Section */
.quick-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.info-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.info-card h3 {
  color: #333;
  margin-bottom: 10px;
}

/* Services Grid */
.services-grid {
  margin-bottom: 40px;
}

.services-grid h2 {
  text-align: center;
  margin-bottom: 30px;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.service-card {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.service-card h3 {
  color: #333;
  margin-bottom: 20px;
}

.service-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.service-card li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.service-card li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #7C8C6F;
}

.price-indicator {
  display: block;
  color: #7C8C6F;
  font-weight: bold;
  margin-top: 15px;
}

/* Why Choose Us */
.why-choose-us {
  margin-bottom: 40px;
}

.why-choose-us h2 {
  text-align: center;
  margin-bottom: 30px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.benefit {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.benefit h3 {
  color: #333;
  margin-bottom: 10px;
}

/* FAQ Section */
.faq-section {
  margin-bottom: 40px;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.faq-item {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.faq-item h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 1.1em;
}

/* CTA Sections */
.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #7C8C6F;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #6b7a5f;
}

.cta-button.secondary {
  background-color: #fff;
  color: #7C8C6F;
  border: 2px solid #7C8C6F;
  margin-left: 15px;
}

.cta-button.secondary:hover {
  background-color: #f8f9fa;
}

.final-cta {
  text-align: center;
  padding: 60px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.final-cta h2 {
  margin-bottom: 20px;
}

.cta-buttons {
  margin-top: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .quick-info,
  .services-container,
  .benefits-grid,
  .faq-grid {
    grid-template-columns: 1fr;
  }

  .hero-section {
    padding: 40px 20px;
  }

  .hero-section h1 {
    font-size: 2em;
  }

  .cta-button {
    display: block;
    margin: 10px 0;
  }

  .cta-button.secondary {
    margin-left: 0;
  }
} 