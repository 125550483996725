.quote-generator {
  /* Reuse most styles from ScreenPrintingOrder.css */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.quote-generator h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.order-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-section h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.2em;
}

.quote-actions {
  width: 100%;
}

.submit-quote-button:hover {
  background-color: #6b7a5f;
}

.checkout-discount-button:hover {
  background-color: #7a9777;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quote-generator {
    padding: 10px;
  }
  
  .order-section {
    padding: 15px;
  }
}

.action-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 120px;
}

.submit-quote-button {
  width: 100%;
  padding: 15px 30px;
  background-color: #7C8C6F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.or-divider {
  margin: 20px 0;
  color: #666;
  font-style: italic;
}

.checkout-discount-button {
  width: 100%;
  padding: 20px 30px;
  background-color: #8BA888;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.checkout-discount-button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(255,255,255,0.1), transparent);
  animation: shine 2s infinite;
}

.discount-note {
  margin-top: 10px;
  color: #666;
  font-size: 0.9em;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
} 