.material-selection {
  display: grid;
  gap: 15px;
  padding: 20px;
}

.material-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.material-card {
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 0; /* Prevents cards from expanding beyond their grid cell */
}

.material-card:hover {
  border-color: #999;
  transform: translateY(-2px);
}

.material-card.selected {
  border-color: #000;
  background-color: #f8f8f8;
}

.material-card h3 {
  margin: 0 0 8px 0;
  font-size: 1.1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.material-card .brand {
  color: #666;
  font-weight: bold;
  margin: 0 0 4px 0;
  font-size: 0.9em;
}

.material-card .style-number {
  color: #888;
  margin: 0 0 8px 0;
  font-size: 0.9em;
}

.material-card .description {
  margin: 0 0 12px 0;
  font-size: 0.9em;
  line-height: 1.4;
}

.material-card .specs {
  font-size: 0.85em;
  color: #666;
}

.material-card .specs p {
  margin: 4px 0;
}

.material-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.material-header h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.price-indicator {
  font-weight: bold;
  color: #2E8B57; /* Forest green color */
}

.material-description {
  color: #666;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.material-details {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.detail-label {
  color: #666;
}

.detail-value {
  font-weight: 500;
}

/* Responsive design for smaller screens */
@media (max-width: 1200px) {
  .material-cards {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 600px) {
  .material-cards {
    grid-template-columns: 1fr;
  }
}

.material-intro {
  grid-column: 1 / -1;
  margin-bottom: 24px;
  max-width: 1200px;
  padding: 0 16px;
}

.material-intro p {
  font-size: 16px;
  line-height: 1.5;
  color: #4a4a4a;
}

.material-intro a {
  color: #007bff;
  text-decoration: none;
}

.material-intro a:hover {
  text-decoration: underline;
} 