/* Import base styles from FloatingPriceDisplay.css */
@import './FloatingPriceDisplay.css';

.floating-quote {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;  /* Reduced padding */
}

.price-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.quote-options {
  display: grid;
  grid-template-columns: 1fr auto 1fr;  /* Changed to accommodate divider */
  gap: 15px;
  margin: 0;
  align-items: stretch;
}

.regular-quote,
.discounted-quote {
  padding: 15px;
  border-radius: 8px;
  background: #f8f8f8;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;  /* Prevent content spillover */
}

.price-summary {
  font-size: 0.9em;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  gap: 12px;  /* Slightly increase gap for better spacing */
  width: 100%;
  box-sizing: border-box;
}

.base-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;  /* Reduced spacing */
}

.additional-fees {
  display: flex;
  gap: 10px;
  color: #666;
  font-size: 0.85em;
  margin: 4px 0;
}

.fee-item {
  white-space: nowrap;
}

.total-line {
  font-weight: bold;
  margin: 8px 0;
  padding-top: 8px;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 8px;
}

.savings-tag {
  color: #4CAF50;
  font-size: 0.9em;
}

.discounted-quote.highlight {
  background: #f0f7f0;
  border: 2px solid #8BA888;
}

.emphasized {
  color: #7C8C6F;
  font-weight: bold;
}

.or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: #666;
  font-style: italic;
}

.or-divider span {
  background: white;
  padding: 5px;
}

.quote-button,
.purchase-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  margin-bottom: 8px;  /* Add space for the note below */
}

.quote-button {
  background-color: #7C8C6F;
  color: white;
}

.quote-button:hover {
  background-color: #6b7a5f;
}

.purchase-button {
  background-color: #8BA888;
  color: white;
  font-weight: bold;
}

.purchase-button:hover {
  background-color: #7a9777;
}

.contact-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
  width: 100%;  /* Ensure it stays within parent */
}

.contact-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
  width: calc(100% - 24px);  /* Account for padding */
  max-width: 100%;  /* Prevent overflow */
  box-sizing: border-box;  /* Include padding in width calculation */
  transition: border-color 0.3s ease;
}

.contact-input:focus {
  border-color: #7C8C6F;
  outline: none;
}

.contact-input::placeholder {
  color: #999;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quote-options {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .or-divider {
    padding: 5px 0;
  }
  
  .contact-fields {
    gap: 6px;
    margin: 8px 0;
  }
  
  .floating-quote {
    padding: 8px;
  }
}

/* Add these new styles */
.completion-checklist {
  margin-bottom: 15px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
}

.completion-checklist h4 {
  margin: 0 0 8px 0;
  color: #333;
  font-size: 0.95em;
}

.completion-checklist ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.completion-checklist li {
  font-size: 0.85em;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.completion-checklist li.complete {
  color: #4CAF50;
  font-weight: 500;
}

.completion-checklist li.incomplete {
  color: #666;
}

.completion-checklist li .status-note {
  font-size: 0.85em;
  color: #999;
  margin-left: 5px;
  font-style: italic;
}

/* Add transition for smooth color change */
.completion-checklist li {
  transition: color 0.3s ease;
}

/* Style the checkmark and circle */
.completion-checklist li::before {
  margin-right: 8px;
  font-weight: bold;
}

.completion-checklist li.complete::before {
  color: #4CAF50;
}

.completion-checklist li.incomplete::before {
  color: #999;
}

.completion-message {
  margin: 10px 0 0;
  font-size: 0.85em;
  color: #2196F3;
  font-style: italic;
  line-height: 1.4;
}

.purchase-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Add styles for the follow-up notes */
.follow-up-note {
  font-size: 0.85em;
  color: #666;
  margin: 8px 0 0;
  text-align: center;
  font-style: italic;
}

.follow-up-note.reassurance {
  color: #2196F3;
  line-height: 1.4;
}

/* Update the discount-details styles */
.discount-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.savings-highlight {
  color: #4CAF50;
  font-weight: bold;
  font-size: 1.1em;
}

.final-total {
  font-size: 1.1em;
  color: #333;
}

/* Update the total-line styles in the discounted quote */
/* .discounted-quote .total-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  margin: 12px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
} */

.purchase-button.two-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  text-align: center;
  align-items: center;
}

.purchase-button.two-line span:first-child {
  font-size: 1.1em;
  font-weight: bold;
}

.purchase-button.two-line span:last-child {
  font-size: 1em;
} 