.Navbar {
  background-color: #f8f5ee;
  padding: 10px;
  border-bottom: 2px solid #2b2b2b;
}

.Navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.nav-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Navbar li {
  margin: 0 15px;
}

.Navbar a {
  color: #2b2b2b;
  text-decoration: none;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 5px 0;
  position: relative;
}

.Navbar a:hover {
  color: #3d3d3d;
}

.Navbar a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #2b2b2b;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.Navbar a:hover::after {
  transform: scaleX(1);
}

.quote-button {
  background-color: #7C8C6F;  /* Muted sage green */
  color: white !important;
  padding: 8px 24px;  /* Increased horizontal padding from 16px to 24px */
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.quote-button:hover {
  background-color: #5F6B54;  /* Darker sage on hover */
  text-decoration: none;
}

.shirt-designer-button {
  /* Match the styling of order-now-button */
  background-color: #8BA888;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
}

.shirt-designer-button:hover {
  background-color: #7a957a;
}

/* Hamburger menu styles */
.hamburger-container {
  display: none;
}

.hamburger-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  padding: 0;
  position: relative;
  width: 30px;
}

.hamburger-line {
  background-color: #2b2b2b;
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  transition: all 0.3s ease;
}

/* Mobile styles */
@media (max-width: 768px) {
  .Navbar {
    padding: 10px 15px;
  }
  
  .Navbar-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .hamburger-container {
    display: block;
    z-index: 1000;
  }
  
  .nav-menu {
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f8f5ee;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Slower transition */
    z-index: 999;
  }
  
  .nav-menu.open {
    max-height: 400px; /* Increased height to ensure all items are visible */
    padding: 15px 0; /* More padding */
  }
  
  .Navbar li {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    visibility: hidden; /* Hide when collapsed */
  }
  
  .nav-menu.open li {
    opacity: 1;
    visibility: visible; /* Show when expanded */
  }
  
  /* Ensure the last item (Order Now button) is visible */
  .nav-menu.open li:last-child {
    opacity: 1;
    visibility: visible;
    margin-top: 15px; /* Add extra space above the button */
    margin-bottom: 10px; /* Add space below the button */
  }
  
  .Navbar a {
    display: block;
    padding: 8px 0;
    width: 100%;
  }
  
  .quote-button {
    margin-top: 5px;
    display: block !important; /* Force display */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0;
    /* Ensure visibility */
    color: white !important;
    background-color: #7C8C6F !important; /* Force background color */
    z-index: 1001; /* Higher z-index */
    position: relative; /* Establish stacking context */
  }
  
  /* Remove the underline animation on mobile */
  .Navbar a::after {
    display: none;
  }
}

/* Alternative layout for medium-sized screens */
@media (min-width: 769px) {
  .nav-menu {
    max-height: none !important;
    position: static;
    box-shadow: none;
  }
}
