/* Digital Printing page specific styles */
.DigitalPrintingPage {
  color: #2b2b2b;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f5ee;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.Masthead {
  text-align: center;
  border-bottom: 2px solid #2b2b2b;
  margin-bottom: 30px;
  padding: 40px 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.Masthead h1 {
  font-size: 4em;
  font-family: "Times New Roman", serif;
  margin: 0;
  letter-spacing: -1px;
}

.Dateline {
  font-style: italic;
  margin: 10px 0 0;
}

.MainStory {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.ImagePlaceholder {
  background-color: #e0dcd3;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border: 1px dashed #2b2b2b;
}

.PromoButtons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.CTA {
  display: inline-block;
  padding: 12px 24px;
  text-decoration: none;
  font-family: "Times New Roman", serif;
  transition: all 0.2s ease;
  margin-top: auto;
}

.CTA.Primary {
  background-color: #2b2b2b;
  color: #f8f5ee;
}

.CTA.Secondary {
  border: 2px solid #2b2b2b;
  color: #2b2b2b;
}

.CTA:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.ContentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.ContentGrid .Feature {
  height: fit-content;
}

.PricingSection {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #d3d0c9;
}

.PricingTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.PriceTable th {
  background: #2b2b2b;
  color: #f8f5ee;
  padding: 12px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
}

.PriceTable td {
  padding: 12px;
  border-bottom: 1px solid #d3d0c9;
}

.PriceNotes {
  margin-top: 20px;
  font-size: 0.9em;
  font-style: italic;
}

.Subtitle {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 20px;
}

.PriceCategory {
  text-align: center;
  padding: 20px;
  background-color: #f8f5ee;
  border: 1px solid #d3d0c9;
}

.PriceCategory h4 {
  margin-bottom: 10px;
  font-family: "Times New Roman", serif;
}

.PricingNote {
  font-style: italic;
  margin-top: 20px;
  font-size: 0.9em;
}

.ApplicationList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.Application {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #d3d0c9;
}

.Application h4 {
  font-size: 1em;
  margin-bottom: 5px;
}

.Application p {
  font-size: 0.9em;
  margin: 0;
  line-height: 1.4;
}

.ThreeColumnSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
}

.SpecsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.SpecItem {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #d3d0c9;
}

.NewsPreview {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #d3d0c9;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.NewsPreview .ReadMore {
  margin-top: auto;
  text-decoration: none;
  color: #2b2b2b;
  font-style: italic;
}

.ActionSections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
}

.DesignSection,
.ContactSection {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #d3d0c9;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DesignSection h3,
.ContactSection h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.DesignSection p,
.ContactSection p {
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .ContentGrid,
  .ThreeColumnSection {
    grid-template-columns: 1fr 1fr;
  }
  
  .ApplicationList {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .Masthead h1 {
    font-size: 3em;
  }
  
  .ContentGrid,
  .ThreeColumnSection,
  .ActionSections {
    grid-template-columns: 1fr;
  }
  
  .PromoButtons {
    flex-direction: column;
    align-items: center;
  }
  
  .ImagePlaceholder {
    height: 250px;
  }
}

/* Inherit the newspaper styling from ScreenPrinting.css */
.Masthead, .Feature, .ArticleGrid {
  /* These styles are inherited from ScreenPrinting.css */
}

/* Digital-specific styles */
.PlaceholderText {
  font-style: italic;
  color: #666;
}

.HighlightBox {
  border: 2px solid #2b2b2b;
  padding: 15px;
  margin: 15px 0;
  text-align: center;
  background-color: #fff;
}

.ColorGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin: 20px 0;
}

.ColorFeature {
  text-align: center;
  padding: 15px;
  border: 1px solid #d3d0c9;
}

.PriceGuide {
  border: 1px solid #2b2b2b;
  padding: 15px;
}

.PriceItem {
  padding: 10px 0;
  border-bottom: 1px solid #d3d0c9;
}

.PriceItem:last-child {
  border-bottom: none;
}

.ContactSection {
  text-align: center;
  margin: 40px 0;
  padding: 20px;
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.DesignSection h3 {
  font-family: "Times New Roman", serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.DesignSection p {
  max-width: 600px;
  margin: 0 auto 20px;
  line-height: 1.6;
}

/* Update spacing between sections */
.DesignSection + .ContactSection {
  margin-top: 20px;
}

.ArticleGrid {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(600px, 2fr);
  gap: 20px;
  margin: 40px auto;
  max-width: 1200px;
  padding: 0 20px;
  align-items: start;
}

/* Ensure the spans are working correctly */
.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

.FullWidthSection {
  width: 100%;
  background-color: #fff;
  padding: 40px 0;
  margin: 40px 0;
}

.ThreeColumnSection .Feature {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ThreeColumnSection .ColorGrid {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ThreeColumnSection .ColorFeature {
  padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .ThreeColumnSection {
    grid-template-columns: 1fr 1fr;
  }
  
  .ThreeColumnSection .Feature:last-child {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .ThreeColumnSection {
    grid-template-columns: 1fr;
  }
  
  .ThreeColumnSection .Feature:last-child {
    grid-column: span 1;
  }
}

/* Add this to your existing CSS */
.QuoteSection.fullWidth {
  grid-column: 1 / -1; /* Make it span all columns */
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 40px;
  background-color: #f8f5ee;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.QuoteSection.fullWidth h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

.QuoteSection.fullWidth p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 25px;
}

.QuoteSection.fullWidth .CTA {
  display: inline-block;
  padding: 12px 25px;
  background-color: #7C8C6F;
  color: white;
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.QuoteSection.fullWidth .CTA:hover {
  background-color: #6a7a5e;
  transform: translateY(-2px);
} 