.design-tools {
  padding: 16px;
  background-color: #f4f1ea;
  border-radius: 8px;
}

.design-tools h2 {
  color: #2b2b2b;
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
}

.tool-section {
  background: white;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(43, 43, 43, 0.1);
}

.tool-section h3 {
  color: #2b2b2b;
  font-size: 16px;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 2px solid #f4f1ea;
}

.tool-button {
  width: 100%;
  padding: 8px;
  background: #2b2b2b;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 8px;
}

.tool-button:hover {
  background: #404040;
  transform: translateY(-1px);
}

.next-button {
  width: 100%;
  padding: 12px;
  background: #2b2b2b;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 16px;
}

.next-button:hover {
  background: #404040;
  transform: translateY(-1px);
} 