.color-selection {
  margin: 20px 0;
  padding: 72px 8px 8px 8px;
  overflow: visible;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 8px;
  padding: 10px;
}

.color-grid.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  max-height: 240px; /* Allows for roughly 3 rows */
  overflow-x: auto;
  overflow-y: visible;
  padding: 8px 72px;
}

.color-swatch {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  padding: 0;
  background-size: cover;
  background-position: center;
  flex: 0 0 auto; /* Prevents growth/shrink */
}

.color-swatch.selected {
  border: 3px solid #7C8C6F;
  box-shadow: 0 0 8px rgba(124, 140, 111, 0.5);
  transform: scale(1.05);
  z-index: 2;
}

.color-name {
  display: none;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1;
  font-size: 12px;
  color: white;
  max-width: 200px;
  text-overflow: ellipsis;
}

.color-swatch:hover .color-name {
  display: block;
}

/* Remove unused preview styles */
.color-preview, .preview-image {
  display: none;
}

.color-selection-message {
  text-align: center;
  padding: 20px;
  color: #666;
}

.color-selection-message.error {
  color: #d32f2f;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .color-name {
    font-size: 10px;
    padding: 2px 4px;
  }
} 